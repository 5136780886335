import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import RechageViewComponent from "../confirmation/index"
import InputMask from "react-input-mask";
import SelectProvider from "./SelectProvider";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);




function DataGridClientes({mobileProviders,changeViewRechage}) {
let { getApi,postApi ,deleteApi} = useAuth();
const [rechargeList, setRechargeList] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);





const [page, setPage] = React.useState(0);
const [limitPage, setLimitPage] = React.useState(5);
const [totalRecords, setTotalRecords] = React.useState(0);
const [selectionModel, setSelectionModel] = useState();
const [row, setRow] = useState([]);


const [openViewRecharge, setOpenViewRecharge] = React.useState(false);

let [saving, setSaving] = useState(false);
  let [errormsg, setErrorMsg] = useState('');


const handleClickOpenEditClient = async (selectedEditValue) => {
  setOpenViewRecharge(true);
  setSelectionModel(selectedEditValue);
};

const handleCloseEditClient = (value) => {
  setOpenViewRecharge(false);
  setSelectionModel(value);
};



const navigate = useNavigate();

const columns = [
  { field: "id", headerName: "ID", width: 150,  hide: true },
  {
    field: "created_at",
    headerName: "Data",
    width: window.isNativeApp?100:200,
    editable: false,
    sortable: false,
  },
  {
    field: "provider_name",
    headerName: "Operadora",
    width: window.isNativeApp?100:200,
    editable: false,
  },
  {
    field: "cell_phone",
    headerName: "Telefone",
    width: window.isNativeApp?100:200,
    editable: false,
  },
  {
    field: "product_amount",
    headerName: "Valor",
    width: window.isNativeApp?100:200,
    editable: false,
    sortable: false,
  },
  {
    field: "product_cashback_amount",
    headerName: "CashBack",
    width: window.isNativeApp?100:200,
    editable: false,
    sortable: false,
    renderCell: (params) => {
     return    <Typography sx={{color:parseFloat(String(params.row.product_cashback_amount).replace(/\D/g, "") )>0 &&'green'}}>{params.row.product_cashback_amount}</Typography>;
    }
  },
  { 
  field: "status_name",
  headerName: "Status",
  editable: false,
  sortable: false,
  width: window.isNativeApp?50:150,
  },
  {
    field: "action",
    headerName: "Ação",
    editable: false,
    sortable: false,
    width: 250,
    renderCell: (params) => (
      <React.Fragment>
        <Button
          variant="contained"
          size="small"
          color={"primaryboot"}
          style={{ marginLeft: 16 }}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={e=> handleClickOpenEditClient(params.row)}
        >
          Comprovante
        </Button>

      </React.Fragment>
    
        
    ),
  },
];



useEffect(() => {

  LoadRechargeList();
  setSelectionModel([]);
},[changeViewRechage]);

const LoadRechargeList = async (page_arg=null, limit_arg=null) => {
    
  setRechargeList([]);
  setLoadingList(true);
  let response = await getApi("/rechages/cellphone",{
      limit:limit_arg!=null?limit_arg:limitPage,
      page:page_arg!=null?(page_arg==0?page_arg:page_arg):(page==0?page:page)
  });
  
  setPage(response.page);
  setLimitPage(response.limit);
  setTotalRecords(response.total)
  FillArrayResult(response.lista.map(x=> x.recharge));
  setLoadingList(false);

};






const FillArrayResult=(data)=>{
  let arrayTMP=[];
  data.forEach(function(curr, index, array){
    let phoneNum=String(curr.area_code+""+curr.cell_phone).replace(/\D/g, "");
    arrayTMP.push({
      alldata:curr,
      id: curr.id,
      product_id: curr.product["id"],
      product_name: curr.product["name"],
      product_amount: parseFloat(curr.product["amount"]).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) ,
      product_cashback_amount: curr.product["cashback"]["real_value"]?parseFloat(curr.product["cashback"]["real_value"]).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}):null,
      

      provider_id: curr.product["provider"]["id"],
      provider_name: curr.product["provider"]["name"],
      nsu: curr.nsu,
      authorization_code: curr.authorization_code,
      cell_phone: "("+String(phoneNum).slice(0,2).toString()+")"+String(phoneNum).slice(2,7).toString()+"-"+String(phoneNum).slice(7,11).toString(),

      
      status_id: curr.status["id"],
      status_name: curr.status["name"],
      created_at:  new Date(curr.created_at).toLocaleDateString('pt-br')+
      " "+
      new Date(curr.created_at).toLocaleTimeString('pt-br')
      
     
    })
    setRechargeList(arrayTMP)
  });

}



  return (
    <Card mb={0}>
      <CardContent pb={1}>
  
       
      </CardContent>
                 
      <Paper>
        <div style={{ height: (limitPage==3?270:270+(limitPage*15)), width: "100%" }}>
      <div style={{ height: 350, width: '100%' }}>

          <DataGrid
            rowsPerPageOptions={[3, 5, 10,15,20,50,100]}
            rows={rechargeList}
            columns={columns}
            pageSize={limitPage}
            rowCount={totalRecords}
            page={page}
            onPageSizeChange={e  => {LoadRechargeList(null, e)  } }
            paginationMode={'server'}
            onPageChange={e  => {LoadRechargeList(e, null)  } }
            loading={loadingList}
            disableMultipleSelection
            onSelectionModelChange={(selection) => {
             
              setRow(selection);
            }}
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página'
              }
            }}

            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Não existe recargas efetuadas
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Nenhum resultado encontrado.
                </Stack>
              )
            }}
            
          />
    
        </div>
        </div>
              

     
   

      </Paper>

      {
        openViewRecharge &&
             <RechageViewComponent
             selectedValue={selectionModel.alldata}
             open={openViewRecharge}
             phone={selectionModel.cell_phone}
             provider={selectionModel.provider_id}
             mobileProviders={mobileProviders}
             rechargeValue={selectionModel["product_id"]}
             recharge={selectionModel.alldata}
            key={openViewRecharge}
             onClose={handleCloseEditClient}
           />
          }
    </Card>

    
  );
}

  function RechargePhones() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState([]);
    let { getApi,postApi ,deleteApi} = useAuth();

    const [provider, setProvider ] = React.useState();
    const [mobileProviders, setMobileProviders] = React.useState([]);
    let [phone, setPhone] = useState('');
    let [rechargeValue, setRechargeValue] = useState();
    let [btnContinueStatus, setBtnContinueStatus] = useState(false);

    
    let [changeViewRechage, setChangeViewRechage] = useState(false);


    useEffect(() => {

      ListProviders();
    },[]);

    useEffect(() => {

      if(String(phone).replace(/\D/g, "").slice(0,2)<=10){
        setBtnContinueStatus(false);
      }else if(String(phone).replace(/\D/g, "").length<11){
        setBtnContinueStatus(false);
      }else if(!provider || (provider<1 && provider>3) ){
        setBtnContinueStatus(false);
      }else if(rechargeValue<0 || !rechargeValue ){
        setBtnContinueStatus(false);
      }else{
        setBtnContinueStatus(true);
      }
      
    },[phone,provider,rechargeValue]);

  
    
    const ListProviders = async (event) => {
  
      let response = await getApi("/rechages/providers/cellphones",{});
      setMobileProviders(response.providers);
   };

   const changeProvider=(provider)=>{
    setProvider(provider);
    setRechargeValue(null);
   }
    
  
    const handleClose = (value) => {
      setOpen(false);
      setSelectedValue(value);
      setChangeViewRechage(!changeViewRechage)
    };

  return (
    <React.Fragment>
      <Helmet title="Recarregue seu celular" />
      <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
      <Typography variant="h3" gutterBottom display="inline">
        Recargas
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/recarga">
          Recargas
        </Link>
        <Typography>Recarregue seu celular</Typography>
      </Breadcrumbs>
      </Grid>
    
        </Grid>

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="10vh"
            textAlign={'center'}
          >
        <Card sx={{ minWidth: 275, marginBottom:10, maxWidth:450 }}>
      <CardContent>
      <Typography fontSize={18}>Recarregue seu celular</Typography>
     {/* <Typography fontSize={14} color="green">Receba de volta 1,5% de cashback em qualquer recarga até o mês de julho.</Typography>*/}
       <Typography fontSize={14} color="green">Receba de volta até 1,5% de cashback em qualquer recarga</Typography> 

      {mobileProviders.length>0 &&
            <SelectProvider setProvider={changeProvider} provider={provider}  mobileProviders={mobileProviders}/>
      }
     
    <FormControl fullWidth sx={{ m: 1 }}>

          <InputMask
                  mask={ ( String(String(phone).replace(/\D/g, "")).substring(2,3) >=5 )?"(99) 9 9999-9999":"(99) 9999-9999"}
                  disabled={false}
                  maskChar="_"
                  placeholder="Nº de Celular"
                  value={phone}
                  fullWidth
                  my={2}
                  onChange={e => setPhone(e.target.value)}
                >
                {() => 
                <TextField   required
                  fullWidth
                  name="phone"
                  type="text"
                  id="phone"
                  autoComplete="phone"
                  placeholder="Telefone"
                />}
                </InputMask>
                <small style={{
                  color:"red"
                }}>{
                 String(phone).replace(/\D/g, "").slice(0,2)<=10  &&
                 String(phone).replace(/\D/g, "").length>2 &&
                   "O número está inválido"
                }</small>
        </FormControl>

        <FormControl fullWidth sx={{mt:1, m: 1}}>
        <InputLabel id="demo-simple-select-standard-label">Valor da Recarga</InputLabel>
        <Select
          label="Valor da Recarga"
          value={rechargeValue??""}
          onChange={e=> setRechargeValue(e.target.value)}
        >
            <MenuItem value="" disabled>
            <em>Selecione o valor da recarga</em>
          </MenuItem>
          { !provider && <MenuItem value={0}>Selecione a operadora primeiro</MenuItem>}
           {  provider&& mobileProviders.find((x) => x.id==provider)["products"].map(el => (
              <MenuItem value={el.id}>{el.name}</MenuItem>
           ))
          }
        </Select>
</FormControl>
<Button variant="contained" sx={{marginTop:2}} disabled={!btnContinueStatus}
onClick={e=> setOpen(true)}>Continuar</Button>
<br></br><br></br>
      <small><i>
        {provider==1 && "*Recarregue a partir de R$20 e concorra a R$100mil e premios imediatos.Acesse: www.vivorecargapremiada.com.br. Participe!"}
        {provider==2 && "*Super novidade, agora Prezao YouTube: 12GB +3GB YouTube + WhatsApp e ligaçoes ilimitadas. So R$29,99por 30 dias. Bora dar o play com o Prezao? Envie Youtube para 2006 e aproveite! Saiba+ claro.com.br."}
        {provider==3 && "*eu TIM Pre TOP agora tem Prime Videio Versao Celular. Assista a Copa do Brasil e milhares de filmese series. Continue recarregando e cadastre-se em www.tim.com.br/Primevideo."}

        </i></small>
      </CardContent>
      </Card>
      </Box>

      <Typography fontSize={18}>Ultimas Recargas</Typography>

      <DataGridClientes mobileProviders={mobileProviders} changeViewRechage={changeViewRechage}/>

      {btnContinueStatus &&
             <RechageViewComponent
             selectedValue={selectedValue}
             open={open}
             phone={phone}
             provider={provider}
             mobileProviders={mobileProviders}
             rechargeValue={rechargeValue}
             key={changeViewRechage}
             recharge={null}
 
             onClose={handleClose}
           />
          }
   
       
       
    </React.Fragment>
  );
}

export default RechargePhones;
