import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import { setSession,setBusinessAccount } from "../utils/jwt";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useLocation } from "react-router";

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.js` file.

const INITIALIZE = "INITIALIZE";
const INITIALIZEBS = "INITIALIZEBS";
const INITIALIZEBSBALANCE = "INITIALIZEBSBALANCE";
const INITIALIZEAUTHCHECK = "INITIALIZEAUTHCHECK";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const ShowModalInit = "ShowModalInit";
const Session_BusinessAccountId="@CacheBank_BusinessAccount"

const initialState = {
  isAuthenticated: false,
  isInitialized: false,

  isAuthenticatedBS: false,
  isValidatedAuth: false,
  isInitializedBS: false,
  user: [],
  businessAccount:[
    allowed_charge_types=> []
  ],
  businessAccountDetails:[],

  isBSAccount:false,

  
  balance_available:null,
  balance_toreceive:null,
  balance_blocked:null,
  balance_updated_at:null,
  balance_received_month:null,
  balance_received_cashback:null,



  
  showModal:null,

};


const URLBASE=process.env.REACT_APP_URL_API;
const URLSTOREBASE=process.env.REACT_APP_STORE_URL_SOCKET;
const URLBASESocket=process.env.REACT_APP_URL_SOCKET;

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isValidatedAuth:action.payload.isValidatedAuth?action.payload.isValidatedAuth:false,
        isAuthenticatedBS:action.payload.isAuthenticatedBS?action.payload.isAuthenticatedBS:false,
        isInitialized: true,
        user: action.payload.user,
        businessAccount:action.payload.businessAccount,
        businessAccountDetails:action.payload.businessAccountDetails
      };
    case INITIALIZEAUTHCHECK:
        return {
          ...state,
          isValidatedAuth:action.payload.isValidatedAuth
    };
    case INITIALIZEBS:
        return {
          ...state,
          isAuthenticatedBS:action.payload.isAuthenticatedBS,
          isInitializedBS:true,
          isBSAccount:action.payload.isBSAccount,
          isValidatedAuth:action.payload.isValidatedAuth?action.payload.isValidatedAuth:false
    };
    case INITIALIZEBSBALANCE:
      return {
        ...state,
        balance_available:action.payload.balance_available,
        balance_toreceive:action.payload.balance_toreceive,
        balance_blocked:action.payload.balance_blocked,
        balance_updated_at:action.payload.balance_updated_at,
        balance_received_month:action.payload.balance_received_month,
        balance_received_cashback:action.payload.balance_received_cashback,
  };

    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case ShowModalInit:
        return {
          ...state,
          showModal:action.payload.showModal
    };
    default:
      return {
        ...state
      };
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  let location = useLocation();
  
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      checkAuth();

      checkAuthBS();

      loadSocketBalance();
      checkAuthBSBALANCE()  ;
    };

    initialize();
  }, []);


  useEffect(
    () => {
        loadSocketBalance();
        checkAuthBSBALANCE()  ;
    },
    [location,state.isValidatedAuth]
  )

   const loadSocketBalance = async()=>{
    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }

    if (accessToken) {
      setSession(accessToken);
        try{
          var pusher = new Pusher('519c867652445042dbfc', {
            authEndpoint: URLBASESocket+'/broadcasting/auth',
            forceTLS: true,
          //   forceTLS: false,
          //    wsHost: '127.0.0.1',
            //  wsPort: 6001,
              cluster: 'us2',
              auth: {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    BusinessAccountId: CacheBank_BusinessAccount,
                    Accept: 'application/json',
                },
            }
          });
      
          var channel = pusher.subscribe('private-BSAccount.'+CacheBank_BusinessAccount);
          channel.bind('BSACCOUNT_balance', function(data) {
         
            if(data.available!=null){
            
              dispatch({
                type: INITIALIZEBSBALANCE,
                payload: {
                  balance_available:data.available,
                  balance_toreceive:data.toReceive,
                  balance_received_month:data.received_month,
                  balance_received_cashback:data.received_cashback,
                  balance_blocked:data.blocked,
                  balance_updated_at:new Date(),
                },
              });

            }
           

          });
        }catch(ex){
    
        }
          
      }

   }

  
  const checkAuthBS = async()=>{
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

      if (CacheBank_BusinessAccount) {
        setBusinessAccount(CacheBank_BusinessAccount);
      }

      if (accessToken) {
        setSession(accessToken);
        let checkIsBsAccountAuth=false;
        let checkIsBsAccount=false;
        let checkAccessCodeValidation=false;

        let response = await axios.post(URLBASE+"/signup/check-record",{
          checks:["checkIsBsAccountAuth","checkIsBsAccount",'checkAccessCodeValidation']
        });
        if(response){
          response=response.data;
          checkIsBsAccountAuth=response.checkIsBsAccountAuth.success;
          checkIsBsAccount=response.checkIsBsAccount.isExist;
          checkAccessCodeValidation=response.checkAccessCodeValidation.success;

        }
        
       
        dispatch({
          type: INITIALIZEBS,
          payload: {
            isAuthenticatedBS: checkIsBsAccountAuth,
            isBSAccount:checkIsBsAccount,
            isValidatedAuth:checkAccessCodeValidation
          },
        });
      } else {
        dispatch({
          type: INITIALIZEBS,
          payload: {
            isAuthenticatedBS:false,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: INITIALIZEBS,
        payload: {
          isAuthenticatedBS: false
        },
      });
    }
  }

  const checkAuthBSBALANCE = async()=>{
    try {
        const accessToken = window.localStorage.getItem("accessToken");
        const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);
        var balance_available=null;
        if (CacheBank_BusinessAccount) {
          setBusinessAccount(CacheBank_BusinessAccount);
        }

        if (accessToken) {
          
             let responseBalance = await axios.get(URLBASE+"/business-account/balance",{
             });

             if(responseBalance){
              responseBalance=responseBalance.data;
              
              if(!responseBalance){
                return ;
              }

              if(responseBalance.available!=null){
                dispatch({
                  type: INITIALIZEBSBALANCE,
                  payload: {
                    balance_available:responseBalance.available,
                    balance_toreceive:responseBalance.toReceive,
                    balance_blocked:responseBalance.blocked,
                    balance_received_month:responseBalance.received_month,
                    balance_received_cashback:responseBalance.received_cashback,
                    balance_updated_at:new Date(),
                  },
                });
              }


          } // fim response Balance 
            
          
       

        } // Fim acess token
          

      
      } catch (err) {
      console.error(err);
      dispatch({
        type: INITIALIZEBSBALANCE,
        payload: {
          balance_available:null,
              balance_toreceive:null,
              balance_blocked:null,
              balance_received_month:null,
              balance_received_cashback:null
        },
      });
    }
  }




  const checkAuth = async()=>{
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

      if (CacheBank_BusinessAccount) {
        setBusinessAccount(CacheBank_BusinessAccount);
      }

      if (accessToken) {
        setSession(accessToken);
        let userValid=false;
        const response = await axios.get(URLBASE+"/user/business_account");
        if(response){
          userValid=response.data.operator?true:false
        }
        
        const { operator,business_account,business_accounts_details } = response.data;
     

        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: userValid,
            user:operator,
            businessAccount:business_account?business_account:null,
            businessAccountDetails:business_accounts_details?business_accounts_details:null,
          },
        });
      } else {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            isAuthenticatedBS:false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }

  const signIn = async (email, password) => {
    const response = await axios.post(URLBASE+"/user/auth", {
      email,
      password,
    });
    if(response.data.success){
      const { token, bsaccounts } = response.data;

      if (bsaccounts) {
        setBusinessAccount(bsaccounts[0]);
      }
  
      setSession(token);

      return response.data;
    }else{
      return response;
    }

    
   
  };
  const ShowModalFunc = async (modal) => {
    dispatch({
      type: ShowModalInit,
      payload: {
        showModal:modal,
      },
    });
  };


  const signOut = async () => {
    const response = await axios.post(URLBASE+"/user/auth/logout",{});
    if(response){
      const { success} = response.data;

      setBusinessAccount(null);
      setSession(null);

      return success;
    }else{
      return response;
    }

    

    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (fullname, email, password, phone) => {
    let responsedata=null;

    const submitAxios = await axios.post(URLBASE+"/signup", {
      fullname,
      email,
      password,
      phone,
    }).catch(error => {
      if (error) {
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;

      const { api_token, user } = submitAxios.data;

      window.localStorage.setItem("accessToken", api_token);
    }
   
    return responsedata;
  };

  const signUpStep2 = async (url,data) => {
    let responsedata=null;

    const submitAxios = await axios.post(URLBASE+url, data ).catch(error => {
      if (error) {
        responsedata=error;
      }
    });

    if(submitAxios){
      responsedata=submitAxios.data;

      const { general_business_account_id } = submitAxios.data;

      window.localStorage.setItem(Session_BusinessAccountId, general_business_account_id);
     
    }
   
    return responsedata;
  };

  


  const putApi = async (url,data) => {
    let responsedata=null;

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }

    const submitAxios = await axios.put(URLBASE+url, data ).catch(error => {
      if (error) {
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };

  const getApi = async (url,data) => {
    let responsedata=null;

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }

    const submitAxios = await axios.get(URLBASE+url, {params:data} ).catch(error => {
      if (error) {
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };

  
  const getStoreApi = async (url,data) => {
    let responsedata=null;

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }

    const submitAxios = await axios.get(URLSTOREBASE+url, {params:data} ).catch(error => {
      if (error) {
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };


  const postStoreApi = async (url,data) => {
    let responsedata=null;

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }

    const submitAxios = await axios.post(URLSTOREBASE+url, data ).catch(error => {
      if (error) {
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };

  
  const putStoreApi = async (url,data) => {
    let responsedata=null;

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }

    const submitAxios = await axios.put(URLSTOREBASE+url, data ).catch(error => {
      if (error) {
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };


  const deleteApi = async (url,data) => {
    let responsedata=null;

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }


    const submitAxios = await axios.delete(URLBASE+url, data ).catch(error => {
      if (error) {
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };


  const postApi = async (url,data) => {
    let responsedata=null;

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }


    const submitAxios = await axios.post(URLBASE+url, data ).catch(error => {
      if (error) {
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };





  const resetPassword = async (email) => {
    let errorMsg;
    const response = await axios.post(URLBASE+"/user/auth/reset/generatecode", {
      email
    }).catch(error => {
      if (error) {
        errorMsg=error;
      }
    });
    
    if(response){
      const { success} = response.data;

      return success;
    }else{
      return errorMsg;
    }

    
   
  };

  
  const resetPasswordStep2 = async ( email,password,token) => {
    const response = await axios.post(URLBASE+"/user/reset/password", {
      email,password,token
    });
    if(response){
      const { success } = response.data;


      if (success) {
      }
  
  
    }else{
      return response;
    }
    
   
  };



  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        resetPasswordStep2,
        getApi,
        putApi,
        getStoreApi,
        putStoreApi,
        postStoreApi,
        signUpStep2,
        postApi,
        deleteApi,
        ShowModalFunc,
        checkAuthBS,
        Session_BusinessAccountId,
        checkAuthBSBALANCE
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
